import React, { FC } from 'react'
import Link from 'next/link'
import LayoutDefault from '@/components/layouts/Layout'
import ImgResponsive from '@/components/elements/ImgResponsive'

const Error404: FC = () => {
  return (
    <LayoutDefault>
      <div className='flex flex-col items-center gap-8 bg-primary px-4 py-32'>
        <ImgResponsive src='/images/404.webp' alt='404 page' width={911} height={603} />
        <Link href='/' className='btn btn-secondary w-max' prefetch={false}>
          Back to home page
        </Link>
      </div>
    </LayoutDefault>
  )
}

export default Error404
